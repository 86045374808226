import {
  REVENUE_SOURCE_REQUEST,
  REVENUE_SOURCE_SUCCESS,
  REVENUE_SOURCE_FAIL,

  CREATE_REVENUE_SOURCE_REQUEST,
  CREATE_REVENUE_SOURCE_SUCCESS,
  CREATE_REVENUE_SOURCE_FAIL,

  UPDATE_REVENUE_SOURCE_REQUEST,
  UPDATE_REVENUE_SOURCE_SUCCESS,
  UPDATE_REVENUE_SOURCE_FAIL,

  DELETE_REVENUE_SOURCE_REQUEST,
  DELETE_REVENUE_SOURCE_SUCCESS,
  DELETE_REVENUE_SOURCE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/RevenueSourceConstant";


// create, update, delete and get all
export const getRevenueSourcesReducer = (state = { revenueSources: [] }, action) => {
  switch (action.type) {
    case REVENUE_SOURCE_REQUEST:
    case CREATE_REVENUE_SOURCE_REQUEST:
    case UPDATE_REVENUE_SOURCE_REQUEST:
    case DELETE_REVENUE_SOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REVENUE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSources: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REVENUE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSources: [...state.revenueSources, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REVENUE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSources: state.revenueSources.map((item) =>
          (item.revenueSourceId === action.payload.revenueSourceId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_REVENUE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSources: action?.payload?.isDelete ?
          state.revenueSources.filter((item) =>
            item.revenueSourceId !== action.payload.revenueSourceId
          )
          :
          state.revenueSources.map((item) =>
            (item.revenueSourceId === action.payload.revenueSourceId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case REVENUE_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REVENUE_SOURCE_FAIL:
    case UPDATE_REVENUE_SOURCE_FAIL:
    case DELETE_REVENUE_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};