// create/post
export const CREATE_TENANT_TYPE_REQUEST = "CREATE_TENANT_TYPE_REQUEST";
export const CREATE_TENANT_TYPE_SUCCESS = "CREATE_TENANT_TYPE_SUCCESS";
export const CREATE_TENANT_TYPE_FAIL = "CREATE_TENANT_TYPE_FAIL";

// get
export const TENANT_TYPE_REQUEST = "TENANT_TYPE_REQUEST";
export const TENANT_TYPE_SUCCESS = "TENANT_TYPE_SUCCESS";
export const TENANT_TYPE_FAIL = "TENANT_TYPE_FAIL";

// update/put
export const UPDATE_TENANT_TYPE_REQUEST = "UPDATE_TENANT_TYPE_REQUEST";
export const UPDATE_TENANT_TYPE_SUCCESS = "UPDATE_TENANT_TYPE_SUCCESS";
export const UPDATE_TENANT_TYPE_FAIL = "UPDATE_TENANT_TYPE_FAIL";

// delete/put
export const DELETE_TENANT_TYPE_REQUEST = "DELETE_TENANT_TYPE_REQUEST";
export const DELETE_TENANT_TYPE_SUCCESS = "DELETE_TENANT_TYPE_SUCCESS";
export const DELETE_TENANT_TYPE_FAIL = "DELETE_TENANT_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";