import {
  ADVANCE_PAYMENT_TYPE_REQUEST,
  ADVANCE_PAYMENT_TYPE_SUCCESS,
  ADVANCE_PAYMENT_TYPE_FAIL,

  CREATE_ADVANCE_PAYMENT_TYPE_REQUEST,
  CREATE_ADVANCE_PAYMENT_TYPE_SUCCESS,
  CREATE_ADVANCE_PAYMENT_TYPE_FAIL,

  UPDATE_ADVANCE_PAYMENT_TYPE_REQUEST,
  UPDATE_ADVANCE_PAYMENT_TYPE_SUCCESS,
  UPDATE_ADVANCE_PAYMENT_TYPE_FAIL,

  DELETE_ADVANCE_PAYMENT_TYPE_REQUEST,
  DELETE_ADVANCE_PAYMENT_TYPE_SUCCESS,
  DELETE_ADVANCE_PAYMENT_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/AdvancePaymentTypeConstant";


// create, update, delete and get all
export const getAdvancePaymentTypesReducer = (state = { advancePaymentTypes: [] }, action) => {
  switch (action.type) {
    case ADVANCE_PAYMENT_TYPE_REQUEST:
    case CREATE_ADVANCE_PAYMENT_TYPE_REQUEST:
    case UPDATE_ADVANCE_PAYMENT_TYPE_REQUEST:
    case DELETE_ADVANCE_PAYMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case ADVANCE_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        advancePaymentTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_ADVANCE_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        advancePaymentTypes: [...state.advancePaymentTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_ADVANCE_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        advancePaymentTypes: state.advancePaymentTypes.map((item) =>
          (item.advancePaymentTypeId === action.payload.advancePaymentTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_ADVANCE_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        advancePaymentTypes: action?.payload?.isDelete ?
          state.advancePaymentTypes.filter((item) =>
            item.advancePaymentTypeId !== action.payload.advancePaymentTypeId
          )
          :
          state.advancePaymentTypes.map((item) =>
            (item.advancePaymentTypeId === action.payload.advancePaymentTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case ADVANCE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_ADVANCE_PAYMENT_TYPE_FAIL:
    case UPDATE_ADVANCE_PAYMENT_TYPE_FAIL:
    case DELETE_ADVANCE_PAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};