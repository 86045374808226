// create/post
export const CREATE_TENANT_BANK_ACCOUNT_REQUEST = "CREATE_TENANT_BANK_ACCOUNT_REQUEST";
export const CREATE_TENANT_BANK_ACCOUNT_SUCCESS = "CREATE_TENANT_BANK_ACCOUNT_SUCCESS";
export const CREATE_TENANT_BANK_ACCOUNT_FAIL = "CREATE_TENANT_BANK_ACCOUNT_FAIL";

// get
export const TENANT_BANK_ACCOUNT_REQUEST = "TENANT_BANK_ACCOUNT_REQUEST";
export const TENANT_BANK_ACCOUNT_SUCCESS = "TENANT_BANK_ACCOUNT_SUCCESS";
export const TENANT_BANK_ACCOUNT_FAIL = "TENANT_BANK_ACCOUNT_FAIL";

// update/put
export const UPDATE_TENANT_BANK_ACCOUNT_REQUEST = "UPDATE_TENANT_BANK_ACCOUNT_REQUEST";
export const UPDATE_TENANT_BANK_ACCOUNT_SUCCESS = "UPDATE_TENANT_BANK_ACCOUNT_SUCCESS";
export const UPDATE_TENANT_BANK_ACCOUNT_FAIL = "UPDATE_TENANT_BANK_ACCOUNT_FAIL";

// delete/put
export const DELETE_TENANT_BANK_ACCOUNT_REQUEST = "DELETE_TENANT_BANK_ACCOUNT_REQUEST";
export const DELETE_TENANT_BANK_ACCOUNT_SUCCESS = "DELETE_TENANT_BANK_ACCOUNT_SUCCESS";
export const DELETE_TENANT_BANK_ACCOUNT_FAIL = "DELETE_TENANT_BANK_ACCOUNT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";