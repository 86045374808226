import {
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,

  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAIL,

  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,

  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/TenantMasters/DepartmentConstant";


// create, update, delete and get all
export const getDepartmentsReducer = (state = { departments: [] }, action) => {
  switch (action.type) {
    case DEPARTMENT_REQUEST:
    case CREATE_DEPARTMENT_REQUEST:
    case UPDATE_DEPARTMENT_REQUEST:
    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: [...state.departments, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: state.departments.map((item) =>
          (item.departmentId === action.payload.departmentId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action?.payload?.isDelete ?
          state.departments.filter((item) =>
            item.departmentId !== action.payload.departmentId
          )
          :
          state.departments.map((item) =>
            (item.departmentId === action.payload.departmentId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_DEPARTMENT_FAIL:
    case UPDATE_DEPARTMENT_FAIL:
    case DELETE_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};