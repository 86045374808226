// create/post
export const CREATE_REVENUE_SOURCE_REQUEST = "CREATE_REVENUE_SOURCE_REQUEST";
export const CREATE_REVENUE_SOURCE_SUCCESS = "CREATE_REVENUE_SOURCE_SUCCESS";
export const CREATE_REVENUE_SOURCE_FAIL = "CREATE_REVENUE_SOURCE_FAIL";

// get
export const REVENUE_SOURCE_REQUEST = "REVENUE_SOURCE_REQUEST";
export const REVENUE_SOURCE_SUCCESS = "REVENUE_SOURCE_SUCCESS";
export const REVENUE_SOURCE_FAIL = "REVENUE_SOURCE_FAIL";

// update/put
export const UPDATE_REVENUE_SOURCE_REQUEST = "UPDATE_REVENUE_SOURCE_REQUEST";
export const UPDATE_REVENUE_SOURCE_SUCCESS = "UPDATE_REVENUE_SOURCE_SUCCESS";
export const UPDATE_REVENUE_SOURCE_FAIL = "UPDATE_REVENUE_SOURCE_FAIL";

// delete/put
export const DELETE_REVENUE_SOURCE_REQUEST = "DELETE_REVENUE_SOURCE_REQUEST";
export const DELETE_REVENUE_SOURCE_SUCCESS = "DELETE_REVENUE_SOURCE_SUCCESS";
export const DELETE_REVENUE_SOURCE_FAIL = "DELETE_REVENUE_SOURCE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";