import {
  DIVISION_REQUEST,
  DIVISION_SUCCESS,
  DIVISION_FAIL,

  CREATE_DIVISION_REQUEST,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_FAIL,

  UPDATE_DIVISION_REQUEST,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_FAIL,

  DELETE_DIVISION_REQUEST,
  DELETE_DIVISION_SUCCESS,
  DELETE_DIVISION_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/TenantMasters/DivisionConstant";


// create, update, delete and get all
export const getDivisionsReducer = (state = { divisions: [] }, action) => {
  switch (action.type) {
    case DIVISION_REQUEST:
    case CREATE_DIVISION_REQUEST:
    case UPDATE_DIVISION_REQUEST:
    case DELETE_DIVISION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DIVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        divisions: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DIVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        divisions: [...state.divisions, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        divisions: state.divisions.map((item) =>
          (item.divisionId === action.payload.divisionId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        divisions: action?.payload?.isDelete ?
          state.divisions.filter((item) =>
            item.divisionId !== action.payload.divisionId
          )
          :
          state.divisions.map((item) =>
            (item.divisionId === action.payload.divisionId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case DIVISION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_DIVISION_FAIL:
    case UPDATE_DIVISION_FAIL:
    case DELETE_DIVISION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};