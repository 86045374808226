// create/post
export const CREATE_COA_SERVICE_MAP_REQUEST = "CREATE_COA_SERVICE_MAP_REQUEST";
export const CREATE_COA_SERVICE_MAP_SUCCESS = "CREATE_COA_SERVICE_MAP_SUCCESS";
export const CREATE_COA_SERVICE_MAP_FAIL = "CREATE_COA_SERVICE_MAP_FAIL";

// get
export const COA_SERVICE_MAP_REQUEST = "COA_SERVICE_MAP_REQUEST";
export const COA_SERVICE_MAP_SUCCESS = "COA_SERVICE_MAP_SUCCESS";
export const COA_SERVICE_MAP_FAIL = "COA_SERVICE_MAP_FAIL";

// update/put
export const UPDATE_COA_SERVICE_MAP_REQUEST = "UPDATE_COA_SERVICE_MAP_REQUEST";
export const UPDATE_COA_SERVICE_MAP_SUCCESS = "UPDATE_COA_SERVICE_MAP_SUCCESS";
export const UPDATE_COA_SERVICE_MAP_FAIL = "UPDATE_COA_SERVICE_MAP_FAIL";

// delete/put
export const DELETE_COA_SERVICE_MAP_REQUEST = "DELETE_COA_SERVICE_MAP_REQUEST";
export const DELETE_COA_SERVICE_MAP_SUCCESS = "DELETE_COA_SERVICE_MAP_SUCCESS";
export const DELETE_COA_SERVICE_MAP_FAIL = "DELETE_COA_SERVICE_MAP_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";