import {
  REWARD_PAYMENT_REQUEST,
  REWARD_PAYMENT_SUCCESS,
  REWARD_PAYMENT_FAIL,

  CREATE_REWARD_PAYMENT_REQUEST,
  CREATE_REWARD_PAYMENT_SUCCESS,
  CREATE_REWARD_PAYMENT_FAIL,

  UPDATE_REWARD_PAYMENT_REQUEST,
  UPDATE_REWARD_PAYMENT_SUCCESS,
  UPDATE_REWARD_PAYMENT_FAIL,

  DELETE_REWARD_PAYMENT_REQUEST,
  DELETE_REWARD_PAYMENT_SUCCESS,
  DELETE_REWARD_PAYMENT_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/RewardPaymentConstant";


// create, update, delete and get all
export const getRewardPaymentsReducer = (state = { rewardPayments: [] }, action) => {
  switch (action.type) {
    case REWARD_PAYMENT_REQUEST:
    case CREATE_REWARD_PAYMENT_REQUEST:
    case UPDATE_REWARD_PAYMENT_REQUEST:
    case DELETE_REWARD_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: [...state.rewardPayments, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: state.rewardPayments.map((item) =>
          (item.rewardPaymentId === action.payload.rewardPaymentId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: action?.payload?.isDelete ?
          state.rewardPayments.filter((item) =>
            item.rewardPaymentId !== action.payload.rewardPaymentId
          )
          :
          state.rewardPayments.map((item) =>
            (item.rewardPaymentId === action.payload.rewardPaymentId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case REWARD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REWARD_PAYMENT_FAIL:
    case UPDATE_REWARD_PAYMENT_FAIL:
    case DELETE_REWARD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};