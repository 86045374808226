import {
  MAJOR_GROUP_REQUEST,
  MAJOR_GROUP_SUCCESS,
  MAJOR_GROUP_FAIL,

  CREATE_MAJOR_GROUP_REQUEST,
  CREATE_MAJOR_GROUP_SUCCESS,
  CREATE_MAJOR_GROUP_FAIL,

  UPDATE_MAJOR_GROUP_REQUEST,
  UPDATE_MAJOR_GROUP_SUCCESS,
  UPDATE_MAJOR_GROUP_FAIL,

  DELETE_MAJOR_GROUP_REQUEST,
  DELETE_MAJOR_GROUP_SUCCESS,
  DELETE_MAJOR_GROUP_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/MajorGroupConstant";


// create, update, delete and get all
export const getMajorGroupsReducer = (state = { majorGroups: [] }, action) => {
  switch (action.type) {
    case MAJOR_GROUP_REQUEST:
    case CREATE_MAJOR_GROUP_REQUEST:
    case UPDATE_MAJOR_GROUP_REQUEST:
    case DELETE_MAJOR_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: [...state.majorGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: state.majorGroups.map((item) =>
          (item.majorGroupId === action.payload.majorGroupId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: action?.payload?.isDelete ?
          state.majorGroups.filter((item) =>
            item.majorGroupId !== action.payload.majorGroupId
          )
          :
          state.majorGroups.map((item) =>
            (item.majorGroupId === action.payload.majorGroupId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_MAJOR_GROUP_FAIL:
    case UPDATE_MAJOR_GROUP_FAIL:
    case DELETE_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};