import {
  CREATE_AMEND_REQUEST,
  CREATE_AMEND_SUCCESS,
  CREATE_AMEND_FAIL,

  AMEND_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/AmendConstant";

export const getAmendReducer = (state = { receiptAmend: {} }, action) => {
  switch (action.type) {
    case CREATE_AMEND_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_AMEND_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAmend: action.payload,
        message: action.message,
        status: action.status,
      };

    case CREATE_AMEND_FAIL:
      return {
        ...state,
        loading: false,
        receiptAmend: {},
        message: action.message,
        status: action.status,
      };

    case AMEND_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_AMEND":
      return {
        ...state,
        receiptAmend: {},
      }

    default:
      return state;
  }
};