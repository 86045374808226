import {
  EXTERNAL_SYSTEM_REQUEST,
  EXTERNAL_SYSTEM_SUCCESS,
  EXTERNAL_SYSTEM_FAIL,

  CREATE_EXTERNAL_SYSTEM_REQUEST,
  CREATE_EXTERNAL_SYSTEM_SUCCESS,
  CREATE_EXTERNAL_SYSTEM_FAIL,

  UPDATE_EXTERNAL_SYSTEM_REQUEST,
  UPDATE_EXTERNAL_SYSTEM_SUCCESS,
  UPDATE_EXTERNAL_SYSTEM_FAIL,

  DELETE_EXTERNAL_SYSTEM_REQUEST,
  DELETE_EXTERNAL_SYSTEM_SUCCESS,
  DELETE_EXTERNAL_SYSTEM_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/ExternalSystemConstant";


// create, update, delete and get all
export const getExternalSystemsReducer = (state = { externalSystems: [] }, action) => {
  switch (action.type) {
    case EXTERNAL_SYSTEM_REQUEST:
    case CREATE_EXTERNAL_SYSTEM_REQUEST:
    case UPDATE_EXTERNAL_SYSTEM_REQUEST:
    case DELETE_EXTERNAL_SYSTEM_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: action.payload,
        message: null,
        status: null,
      };

    case CREATE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: [...state.externalSystems, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: state.externalSystems.map((item) =>
          (item.externalSystemId === action.payload.externalSystemId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: action?.payload?.isDelete ?
          state.externalSystems.filter((item) =>
            item.externalSystemId !== action.payload.externalSystemId
          )
          :
          state.externalSystems.map((item) =>
            (item.externalSystemId === action.payload.externalSystemId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case EXTERNAL_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_EXTERNAL_SYSTEM_FAIL:
    case UPDATE_EXTERNAL_SYSTEM_FAIL:
    case DELETE_EXTERNAL_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};