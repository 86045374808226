import {
  REVENUE_SUB_TYPE_REQUEST,
  REVENUE_SUB_TYPE_SUCCESS,
  REVENUE_SUB_TYPE_FAIL,

  CREATE_REVENUE_SUB_TYPE_REQUEST,
  CREATE_REVENUE_SUB_TYPE_SUCCESS,
  CREATE_REVENUE_SUB_TYPE_FAIL,

  UPDATE_REVENUE_SUB_TYPE_REQUEST,
  UPDATE_REVENUE_SUB_TYPE_SUCCESS,
  UPDATE_REVENUE_SUB_TYPE_FAIL,

  DELETE_REVENUE_SUB_TYPE_REQUEST,
  DELETE_REVENUE_SUB_TYPE_SUCCESS,
  DELETE_REVENUE_SUB_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/RevenueSubTypeConstant";


// create, update, delete and get all
export const getRevenueSubTypesReducer = (state = { revenueSubTypes: [] }, action) => {
  switch (action.type) {
    case REVENUE_SUB_TYPE_REQUEST:
    case CREATE_REVENUE_SUB_TYPE_REQUEST:
    case UPDATE_REVENUE_SUB_TYPE_REQUEST:
    case DELETE_REVENUE_SUB_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REVENUE_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSubTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REVENUE_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSubTypes: [...state.revenueSubTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REVENUE_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSubTypes: state.revenueSubTypes.map((item) =>
          (item.revenueSubTypeId === action.payload.revenueSubTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_REVENUE_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueSubTypes: action?.payload?.isDelete ?
          state.revenueSubTypes.filter((item) =>
            item.revenueSubTypeId !== action.payload.revenueSubTypeId
          )
          :
          state.revenueSubTypes.map((item) =>
            (item.revenueSubTypeId === action.payload.revenueSubTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case REVENUE_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REVENUE_SUB_TYPE_FAIL:
    case UPDATE_REVENUE_SUB_TYPE_FAIL:
    case DELETE_REVENUE_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};