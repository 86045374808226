// create/post
export const CREATE_REVENUE_TYPE_REQUEST = "CREATE_REVENUE_TYPE_REQUEST";
export const CREATE_REVENUE_TYPE_SUCCESS = "CREATE_REVENUE_TYPE_SUCCESS";
export const CREATE_REVENUE_TYPE_FAIL = "CREATE_REVENUE_TYPE_FAIL";

// get
export const REVENUE_TYPE_REQUEST = "REVENUE_TYPE_REQUEST";
export const REVENUE_TYPE_SUCCESS = "REVENUE_TYPE_SUCCESS";
export const REVENUE_TYPE_FAIL = "REVENUE_TYPE_FAIL";

// update/put
export const UPDATE_REVENUE_TYPE_REQUEST = "UPDATE_REVENUE_TYPE_REQUEST";
export const UPDATE_REVENUE_TYPE_SUCCESS = "UPDATE_REVENUE_TYPE_SUCCESS";
export const UPDATE_REVENUE_TYPE_FAIL = "UPDATE_REVENUE_TYPE_FAIL";

// delete/put
export const DELETE_REVENUE_TYPE_REQUEST = "DELETE_REVENUE_TYPE_REQUEST";
export const DELETE_REVENUE_TYPE_SUCCESS = "DELETE_REVENUE_TYPE_SUCCESS";
export const DELETE_REVENUE_TYPE_FAIL = "DELETE_REVENUE_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";