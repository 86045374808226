// create/post
export const CREATE_REVENUE_GL_HEAD_REQUEST = "CREATE_REVENUE_GL_HEAD_REQUEST";
export const CREATE_REVENUE_GL_HEAD_SUCCESS = "CREATE_REVENUE_GL_HEAD_SUCCESS";
export const CREATE_REVENUE_GL_HEAD_FAIL = "CREATE_REVENUE_GL_HEAD_FAIL";

// get
export const REVENUE_GL_HEAD_REQUEST = "REVENUE_GL_HEAD_REQUEST";
export const REVENUE_GL_HEAD_SUCCESS = "REVENUE_GL_HEAD_SUCCESS";
export const REVENUE_GL_HEAD_FAIL = "REVENUE_GL_HEAD_FAIL";

// update/put
export const UPDATE_REVENUE_GL_HEAD_REQUEST = "UPDATE_REVENUE_GL_HEAD_REQUEST";
export const UPDATE_REVENUE_GL_HEAD_SUCCESS = "UPDATE_REVENUE_GL_HEAD_SUCCESS";
export const UPDATE_REVENUE_GL_HEAD_FAIL = "UPDATE_REVENUE_GL_HEAD_FAIL";

// delete/put
export const DELETE_REVENUE_GL_HEAD_REQUEST = "DELETE_REVENUE_GL_HEAD_REQUEST";
export const DELETE_REVENUE_GL_HEAD_SUCCESS = "DELETE_REVENUE_GL_HEAD_SUCCESS";
export const DELETE_REVENUE_GL_HEAD_FAIL = "DELETE_REVENUE_GL_HEAD_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";