// create/post
export const CREATE_TRANSACTION_TYPE_REQUEST = "CREATE_TRANSACTION_TYPE_REQUEST";
export const CREATE_TRANSACTION_TYPE_SUCCESS = "CREATE_TRANSACTION_TYPE_SUCCESS";
export const CREATE_TRANSACTION_TYPE_FAIL = "CREATE_TRANSACTION_TYPE_FAIL";

// get
export const TRANSACTION_TYPE_REQUEST = "TRANSACTION_TYPE_REQUEST";
export const TRANSACTION_TYPE_SUCCESS = "TRANSACTION_TYPE_SUCCESS";
export const TRANSACTION_TYPE_FAIL = "TRANSACTION_TYPE_FAIL";

// update/put
export const UPDATE_TRANSACTION_TYPE_REQUEST = "UPDATE_TRANSACTION_TYPE_REQUEST";
export const UPDATE_TRANSACTION_TYPE_SUCCESS = "UPDATE_TRANSACTION_TYPE_SUCCESS";
export const UPDATE_TRANSACTION_TYPE_FAIL = "UPDATE_TRANSACTION_TYPE_FAIL";

// delete/put
export const DELETE_TRANSACTION_TYPE_REQUEST = "DELETE_TRANSACTION_TYPE_REQUEST";
export const DELETE_TRANSACTION_TYPE_SUCCESS = "DELETE_TRANSACTION_TYPE_SUCCESS";
export const DELETE_TRANSACTION_TYPE_FAIL = "DELETE_TRANSACTION_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";