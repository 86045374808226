import {
  TRANSACTION_TYPE_REQUEST,
  TRANSACTION_TYPE_SUCCESS,
  TRANSACTION_TYPE_FAIL,

  CREATE_TRANSACTION_TYPE_REQUEST,
  CREATE_TRANSACTION_TYPE_SUCCESS,
  CREATE_TRANSACTION_TYPE_FAIL,

  UPDATE_TRANSACTION_TYPE_REQUEST,
  UPDATE_TRANSACTION_TYPE_SUCCESS,
  UPDATE_TRANSACTION_TYPE_FAIL,

  DELETE_TRANSACTION_TYPE_REQUEST,
  DELETE_TRANSACTION_TYPE_SUCCESS,
  DELETE_TRANSACTION_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/TransactionTypeConstant";


// create, update, delete and get all
export const getTransactionTypesReducer = (state = { transactionTypes: [] }, action) => {
  switch (action.type) {
    case TRANSACTION_TYPE_REQUEST:
    case CREATE_TRANSACTION_TYPE_REQUEST:
    case UPDATE_TRANSACTION_TYPE_REQUEST:
    case DELETE_TRANSACTION_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTypes: [...state.transactionTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTypes: state.transactionTypes.map((item) =>
          (item.transactionTypeId === action.payload.transactionTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionTypes: action?.payload?.isDelete ?
          state.transactionTypes.filter((item) =>
            item.transactionTypeId !== action.payload.transactionTypeId
          )
          :
          state.transactionTypes.map((item) =>
            (item.transactionTypeId === action.payload.transactionTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case TRANSACTION_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_TRANSACTION_TYPE_FAIL:
    case UPDATE_TRANSACTION_TYPE_FAIL:
    case DELETE_TRANSACTION_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};