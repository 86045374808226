// create/post
export const CREATE_GEWOG_REQUEST = "CREATE_GEWOG_REQUEST";
export const CREATE_GEWOG_SUCCESS = "CREATE_GEWOG_SUCCESS";
export const CREATE_GEWOG_FAIL = "CREATE_GEWOG_FAIL";

// get
export const GEWOG_REQUEST = "GEWOG_REQUEST";
export const GEWOG_SUCCESS = "GEWOG_SUCCESS";
export const GEWOG_FAIL = "GEWOG_FAIL";

// update/put
export const UPDATE_GEWOG_REQUEST = "UPDATE_GEWOG_REQUEST";
export const UPDATE_GEWOG_SUCCESS = "UPDATE_GEWOG_SUCCESS";
export const UPDATE_GEWOG_FAIL = "UPDATE_GEWOG_FAIL";

// delete/put
export const DELETE_GEWOG_REQUEST = "DELETE_GEWOG_REQUEST";
export const DELETE_GEWOG_SUCCESS = "DELETE_GEWOG_SUCCESS";
export const DELETE_GEWOG_FAIL = "DELETE_GEWOG_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";