import {
  STATUS_MASTER_REQUEST,
  STATUS_MASTER_SUCCESS,
  STATUS_MASTER_FAIL,

  CREATE_STATUS_MASTER_REQUEST,
  CREATE_STATUS_MASTER_SUCCESS,
  CREATE_STATUS_MASTER_FAIL,

  UPDATE_STATUS_MASTER_REQUEST,
  UPDATE_STATUS_MASTER_SUCCESS,
  UPDATE_STATUS_MASTER_FAIL,

  DELETE_STATUS_MASTER_REQUEST,
  DELETE_STATUS_MASTER_SUCCESS,
  DELETE_STATUS_MASTER_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/StatusMasterConstant";


// create, update, delete and get all
export const getStatusMastersReducer = (state = { statusMasters: [] }, action) => {
  switch (action.type) {
    case STATUS_MASTER_REQUEST:
    case CREATE_STATUS_MASTER_REQUEST:
    case UPDATE_STATUS_MASTER_REQUEST:
    case DELETE_STATUS_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case STATUS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: action.payload,
        message: null,
        status: null,
      };

    case CREATE_STATUS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: [...state.statusMasters, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_STATUS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: state.statusMasters.map((item) =>
          (item.statusId === action.payload.statusId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_STATUS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: action?.payload?.isDelete ?
          state.statusMasters.filter((item) =>
            item.statusId !== action.payload.statusId
          )
          :
          state.statusMasters.map((item) =>
            (item.statusId === action.payload.statusId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case STATUS_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_STATUS_MASTER_FAIL:
    case UPDATE_STATUS_MASTER_FAIL:
    case DELETE_STATUS_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};