import {
  GEWOG_REQUEST,
  GEWOG_SUCCESS,
  GEWOG_FAIL,

  CREATE_GEWOG_REQUEST,
  CREATE_GEWOG_SUCCESS,
  CREATE_GEWOG_FAIL,

  UPDATE_GEWOG_REQUEST,
  UPDATE_GEWOG_SUCCESS,
  UPDATE_GEWOG_FAIL,

  DELETE_GEWOG_REQUEST,
  DELETE_GEWOG_SUCCESS,
  DELETE_GEWOG_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/LocationMasters/GewogConstant";


// create, update, delete and get all
export const getGewogsReducer = (state = { gewogs: [] }, action) => {
  switch (action.type) {
    case GEWOG_REQUEST:
    case CREATE_GEWOG_REQUEST:
    case UPDATE_GEWOG_REQUEST:
    case DELETE_GEWOG_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case GEWOG_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: action.payload,
        message: null,
        status: null,
      };

    case CREATE_GEWOG_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: [...state.gewogs, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_GEWOG_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: state.gewogs.map((item) =>
          (item.gewogId === action.payload.gewogId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_GEWOG_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: action?.payload?.isDelete ?
          state.gewogs.filter((item) =>
            item.gewogId !== action.payload.gewogId
          )
          :
          state.gewogs.map((item) =>
            (item.gewogId === action.payload.gewogId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case GEWOG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_GEWOG_FAIL:
    case UPDATE_GEWOG_FAIL:
    case DELETE_GEWOG_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};