// get
export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

// update/put
export const UPDATE_USER_DATA_REQUEST = "UPDATE_USER_DATA_REQUEST";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDATE_USER_DATA_FAIL";

// delete/put
export const DELETE_USER_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";

// find
export const FIND_CURRENT_USER_DATA_REQUEST = "FIND_CURRENT_USER_DATA_REQUEST";
export const FIND_CURRENT_USER_DATA_SUCCESS = "FIND_CURRENT_USER_DATA_SUCCESS";
export const FIND_CURRENT_USER_DATA_FAIL = "FIND_CURRENT_USER_DATA_FAIL";

// find by id
export const FIND_CURRENT_USER_TENANT_REQUEST = "FIND_CURRENT_USER_TENANT_REQUEST";
export const FIND_CURRENT_USER_TENANT_SUCCESS = "FIND_CURRENT_USER_TENANT_SUCCESS";
export const FIND_CURRENT_USER_TENANT_FAIL = "FIND_CURRENT_USER_TENANT_FAIL";

// lock
export const LOCK_USER_DATA_REQUEST = "LOCK_USER_DATA_REQUEST";
export const LOCK_USER_DATA_SUCCESS = "LOCK_USER_DATA_SUCCESS";
export const LOCK_USER_DATA_FAIL = "LOCK_USER_DATA_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";