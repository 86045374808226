import {
  TENANT_REQUEST,
  TENANT_SUCCESS,
  TENANT_FAIL,

  CREATE_TENANT_REQUEST,
  CREATE_TENANT_SUCCESS,
  CREATE_TENANT_FAIL,

  UPDATE_TENANT_REQUEST,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAIL,

  DELETE_TENANT_REQUEST,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/TenantMasters/TenantConstant";


// create, update, delete and get all
export const getTenantsReducer = (state = { tenants: [] }, action) => {
  switch (action.type) {
    case TENANT_REQUEST:
    case CREATE_TENANT_REQUEST:
    case UPDATE_TENANT_REQUEST:
    case DELETE_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenants: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenants: [...state.tenants, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenants: state.tenants.map((item) =>
          (item.tenantId === action.payload.tenantId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenants: action?.payload?.isDelete ?
          state.tenants.filter((item) =>
            item.tenantId !== action.payload.tenantId
          )
          :
          state.tenants.map((item) =>
            (item.tenantId === action.payload.tenantId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case TENANT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_TENANT_FAIL:
    case UPDATE_TENANT_FAIL:
    case DELETE_TENANT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};