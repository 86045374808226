// create/post
export const CREATE_EXTERNAL_SYSTEM_REQUEST = "CREATE_EXTERNAL_SYSTEM_REQUEST";
export const CREATE_EXTERNAL_SYSTEM_SUCCESS = "CREATE_EXTERNAL_SYSTEM_SUCCESS";
export const CREATE_EXTERNAL_SYSTEM_FAIL = "CREATE_EXTERNAL_SYSTEM_FAIL";

// get
export const EXTERNAL_SYSTEM_REQUEST = "EXTERNAL_SYSTEM_REQUEST";
export const EXTERNAL_SYSTEM_SUCCESS = "EXTERNAL_SYSTEM_SUCCESS";
export const EXTERNAL_SYSTEM_FAIL = "EXTERNAL_SYSTEM_FAIL";

// update/put
export const UPDATE_EXTERNAL_SYSTEM_REQUEST = "UPDATE_EXTERNAL_SYSTEM_REQUEST";
export const UPDATE_EXTERNAL_SYSTEM_SUCCESS = "UPDATE_EXTERNAL_SYSTEM_SUCCESS";
export const UPDATE_EXTERNAL_SYSTEM_FAIL = "UPDATE_EXTERNAL_SYSTEM_FAIL";

// delete/put
export const DELETE_EXTERNAL_SYSTEM_REQUEST = "DELETE_EXTERNAL_SYSTEM_REQUEST";
export const DELETE_EXTERNAL_SYSTEM_SUCCESS = "DELETE_EXTERNAL_SYSTEM_SUCCESS";
export const DELETE_EXTERNAL_SYSTEM_FAIL = "DELETE_EXTERNAL_SYSTEM_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";