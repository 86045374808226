// create/post
export const CREATE_TENANT_REQUEST = "CREATE_TENANT_REQUEST";
export const CREATE_TENANT_SUCCESS = "CREATE_TENANT_SUCCESS";
export const CREATE_TENANT_FAIL = "CREATE_TENANT_FAIL";

// get
export const TENANT_REQUEST = "TENANT_REQUEST";
export const TENANT_SUCCESS = "TENANT_SUCCESS";
export const TENANT_FAIL = "TENANT_FAIL";

// update/put
export const UPDATE_TENANT_REQUEST = "UPDATE_TENANT_REQUEST";
export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
export const UPDATE_TENANT_FAIL = "UPDATE_TENANT_FAIL";

// delete/put
export const DELETE_TENANT_REQUEST = "DELETE_TENANT_REQUEST";
export const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS";
export const DELETE_TENANT_FAIL = "DELETE_TENANT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";