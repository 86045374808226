// create/post
export const CREATE_COA_GROUP_REQUEST = "CREATE_COA_GROUP_REQUEST";
export const CREATE_COA_GROUP_SUCCESS = "CREATE_COA_GROUP_SUCCESS";
export const CREATE_COA_GROUP_FAIL = "CREATE_COA_GROUP_FAIL";

// get
export const COA_GROUP_REQUEST = "COA_GROUP_REQUEST";
export const COA_GROUP_SUCCESS = "COA_GROUP_SUCCESS";
export const COA_GROUP_FAIL = "COA_GROUP_FAIL";

// update/put
export const UPDATE_COA_GROUP_REQUEST = "UPDATE_COA_GROUP_REQUEST";
export const UPDATE_COA_GROUP_SUCCESS = "UPDATE_COA_GROUP_SUCCESS";
export const UPDATE_COA_GROUP_FAIL = "UPDATE_COA_GROUP_FAIL";

// delete/put
export const DELETE_COA_GROUP_REQUEST = "DELETE_COA_GROUP_REQUEST";
export const DELETE_COA_GROUP_SUCCESS = "DELETE_COA_GROUP_SUCCESS";
export const DELETE_COA_GROUP_FAIL = "DELETE_COA_GROUP_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";