import {
  MODE_OF_PAYMENT_REQUEST,
  MODE_OF_PAYMENT_SUCCESS,
  MODE_OF_PAYMENT_FAIL,

  CREATE_MODE_OF_PAYMENT_REQUEST,
  CREATE_MODE_OF_PAYMENT_SUCCESS,
  CREATE_MODE_OF_PAYMENT_FAIL,

  UPDATE_MODE_OF_PAYMENT_REQUEST,
  UPDATE_MODE_OF_PAYMENT_SUCCESS,
  UPDATE_MODE_OF_PAYMENT_FAIL,

  DELETE_MODE_OF_PAYMENT_REQUEST,
  DELETE_MODE_OF_PAYMENT_SUCCESS,
  DELETE_MODE_OF_PAYMENT_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/ModeOfPaymentConstant";


// create, update, delete and get all
export const getModeOfPaymentsReducer = (state = { modeOfPayments: [] }, action) => {
  switch (action.type) {
    case MODE_OF_PAYMENT_REQUEST:
    case CREATE_MODE_OF_PAYMENT_REQUEST:
    case UPDATE_MODE_OF_PAYMENT_REQUEST:
    case DELETE_MODE_OF_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: [...state.modeOfPayments, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: state.modeOfPayments.map((item) =>
          (item.paymentModeId === action.payload.paymentModeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: action?.payload?.isDelete ?
          state.modeOfPayments.filter((item) =>
            item.paymentModeId !== action.payload.paymentModeId
          )
          :
          state.modeOfPayments.map((item) =>
            (item.paymentModeId === action.payload.paymentModeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case MODE_OF_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_MODE_OF_PAYMENT_FAIL:
    case UPDATE_MODE_OF_PAYMENT_FAIL:
    case DELETE_MODE_OF_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};