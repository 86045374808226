// create/post
export const CREATE_REVENUE_GROUP_REQUEST = "CREATE_REVENUE_GROUP_REQUEST";
export const CREATE_REVENUE_GROUP_SUCCESS = "CREATE_REVENUE_GROUP_SUCCESS";
export const CREATE_REVENUE_GROUP_FAIL = "CREATE_REVENUE_GROUP_FAIL";

// get
export const REVENUE_GROUP_REQUEST = "REVENUE_GROUP_REQUEST";
export const REVENUE_GROUP_SUCCESS = "REVENUE_GROUP_SUCCESS";
export const REVENUE_GROUP_FAIL = "REVENUE_GROUP_FAIL";

// update/put
export const UPDATE_REVENUE_GROUP_REQUEST = "UPDATE_REVENUE_GROUP_REQUEST";
export const UPDATE_REVENUE_GROUP_SUCCESS = "UPDATE_REVENUE_GROUP_SUCCESS";
export const UPDATE_REVENUE_GROUP_FAIL = "UPDATE_REVENUE_GROUP_FAIL";

// delete/put
export const DELETE_REVENUE_GROUP_REQUEST = "DELETE_REVENUE_GROUP_REQUEST";
export const DELETE_REVENUE_GROUP_SUCCESS = "DELETE_REVENUE_GROUP_SUCCESS";
export const DELETE_REVENUE_GROUP_FAIL = "DELETE_REVENUE_GROUP_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";