import {
  TENANT_BANK_ACCOUNT_REQUEST,
  TENANT_BANK_ACCOUNT_SUCCESS,
  TENANT_BANK_ACCOUNT_FAIL,

  CREATE_TENANT_BANK_ACCOUNT_REQUEST,
  CREATE_TENANT_BANK_ACCOUNT_SUCCESS,
  CREATE_TENANT_BANK_ACCOUNT_FAIL,

  UPDATE_TENANT_BANK_ACCOUNT_REQUEST,
  UPDATE_TENANT_BANK_ACCOUNT_SUCCESS,
  UPDATE_TENANT_BANK_ACCOUNT_FAIL,

  DELETE_TENANT_BANK_ACCOUNT_REQUEST,
  DELETE_TENANT_BANK_ACCOUNT_SUCCESS,
  DELETE_TENANT_BANK_ACCOUNT_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/TenantBankAccountConstant";


// create, update, delete and get all
export const getTenantBankAccountsReducer = (state = { tenantBankAccounts: [] }, action) => {
  switch (action.type) {
    case TENANT_BANK_ACCOUNT_REQUEST:
    case CREATE_TENANT_BANK_ACCOUNT_REQUEST:
    case UPDATE_TENANT_BANK_ACCOUNT_REQUEST:
    case DELETE_TENANT_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case TENANT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccounts: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TENANT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccounts: [...state.tenantBankAccounts, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TENANT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccounts: state.tenantBankAccounts.map((item) =>
          (item.drcAccountId === action.payload.drcAccountId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_TENANT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccounts: action?.payload?.isDelete ?
          state.tenantBankAccounts.filter((item) =>
            item.drcAccountId !== action.payload.drcAccountId
          )
          :
          state.tenantBankAccounts.map((item) =>
            (item.drcAccountId === action.payload.drcAccountId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case TENANT_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_TENANT_BANK_ACCOUNT_FAIL:
    case UPDATE_TENANT_BANK_ACCOUNT_FAIL:
    case DELETE_TENANT_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};