import {
  VILLAGE_REQUEST,
  VILLAGE_SUCCESS,
  VILLAGE_FAIL,

  CREATE_VILLAGE_REQUEST,
  CREATE_VILLAGE_SUCCESS,
  CREATE_VILLAGE_FAIL,

  UPDATE_VILLAGE_REQUEST,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,

  DELETE_VILLAGE_REQUEST,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/LocationMasters/VillageConstant";


// create, update, delete and get all
export const getVillagesReducer = (state = { villages: [] }, action) => {
  switch (action.type) {
    case VILLAGE_REQUEST:
    case CREATE_VILLAGE_REQUEST:
    case UPDATE_VILLAGE_REQUEST:
    case DELETE_VILLAGE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: action.payload,
        message: null,
        status: null,
      };

    case CREATE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: [...state.villages, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: state.villages.map((item) =>
          (item.villageId === action.payload.villageId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: action?.payload?.isDelete ?
          state.villages.filter((item) =>
            item.villageId !== action.payload.villageId
          )
          :
          state.villages.map((item) =>
            (item.villageId === action.payload.villageId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_VILLAGE_FAIL:
    case UPDATE_VILLAGE_FAIL:
    case DELETE_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};