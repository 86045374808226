import {
  REVENUE_TYPE_REQUEST,
  REVENUE_TYPE_SUCCESS,
  REVENUE_TYPE_FAIL,

  CREATE_REVENUE_TYPE_REQUEST,
  CREATE_REVENUE_TYPE_SUCCESS,
  CREATE_REVENUE_TYPE_FAIL,

  UPDATE_REVENUE_TYPE_REQUEST,
  UPDATE_REVENUE_TYPE_SUCCESS,
  UPDATE_REVENUE_TYPE_FAIL,

  DELETE_REVENUE_TYPE_REQUEST,
  DELETE_REVENUE_TYPE_SUCCESS,
  DELETE_REVENUE_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/RevenueTypeConstant";


// create, update, delete and get all
export const getRevenueTypesReducer = (state = { revenueTypes: [] }, action) => {
  switch (action.type) {
    case REVENUE_TYPE_REQUEST:
    case CREATE_REVENUE_TYPE_REQUEST:
    case UPDATE_REVENUE_TYPE_REQUEST:
    case DELETE_REVENUE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REVENUE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REVENUE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueTypes: [...state.revenueTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REVENUE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueTypes: state.revenueTypes.map((item) =>
          (item.revenueTypeId === action.payload.revenueTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_REVENUE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueTypes: action?.payload?.isDelete ?
          state.revenueTypes.filter((item) =>
            item.revenueTypeId !== action.payload.revenueTypeId
          )
          :
          state.revenueTypes.map((item) =>
            (item.revenueTypeId === action.payload.revenueTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case REVENUE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REVENUE_TYPE_FAIL:
    case UPDATE_REVENUE_TYPE_FAIL:
    case DELETE_REVENUE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};