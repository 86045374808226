// create/post
export const CREATE_DIVISION_REQUEST = "CREATE_DIVISION_REQUEST";
export const CREATE_DIVISION_SUCCESS = "CREATE_DIVISION_SUCCESS";
export const CREATE_DIVISION_FAIL = "CREATE_DIVISION_FAIL";

// get
export const DIVISION_REQUEST = "DIVISION_REQUEST";
export const DIVISION_SUCCESS = "DIVISION_SUCCESS";
export const DIVISION_FAIL = "DIVISION_FAIL";

// update/put
export const UPDATE_DIVISION_REQUEST = "UPDATE_DIVISION_REQUEST";
export const UPDATE_DIVISION_SUCCESS = "UPDATE_DIVISION_SUCCESS";
export const UPDATE_DIVISION_FAIL = "UPDATE_DIVISION_FAIL";

// delete/put
export const DELETE_DIVISION_REQUEST = "DELETE_DIVISION_REQUEST";
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS";
export const DELETE_DIVISION_FAIL = "DELETE_DIVISION_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";