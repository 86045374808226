import {
  COUNTRY_REQUEST,
  COUNTRY_SUCCESS,
  COUNTRY_FAIL,

  CREATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAIL,

  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,

  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/CountryConstant";


// create, update, delete and get all
export const getCountriesReducer = (state = { countries: [] }, action) => {
  switch (action.type) {
    case COUNTRY_REQUEST:
    case CREATE_COUNTRY_REQUEST:
    case UPDATE_COUNTRY_REQUEST:
    case DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: [...state.countries, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: state.countries.map((item) =>
          (item.countryId === action.payload.countryId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action?.payload?.isDelete ?
          state.countries.filter((item) =>
            item.countryId !== action.payload.countryId
          )
          :
          state.countries.map((item) =>
            (item.countryId === action.payload.countryId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_COUNTRY_FAIL:
    case UPDATE_COUNTRY_FAIL:
    case DELETE_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};