// create/post
export const CREATE_BANK_REQUEST = "CREATE_BANK_REQUEST";
export const CREATE_BANK_SUCCESS = "CREATE_BANK_SUCCESS";
export const CREATE_BANK_FAIL = "CREATE_BANK_FAIL";

// get
export const BANK_REQUEST = "BANK_REQUEST";
export const BANK_SUCCESS = "BANK_SUCCESS";
export const BANK_FAIL = "BANK_FAIL";

// update/put
export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAIL = "UPDATE_BANK_FAIL";

// delete/put
export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAIL = "DELETE_BANK_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";