import {
  BANK_BRANCH_REQUEST,
  BANK_BRANCH_SUCCESS,
  BANK_BRANCH_FAIL,

  CREATE_BANK_BRANCH_REQUEST,
  CREATE_BANK_BRANCH_SUCCESS,
  CREATE_BANK_BRANCH_FAIL,

  UPDATE_BANK_BRANCH_REQUEST,
  UPDATE_BANK_BRANCH_SUCCESS,
  UPDATE_BANK_BRANCH_FAIL,

  DELETE_BANK_BRANCH_REQUEST,
  DELETE_BANK_BRANCH_SUCCESS,
  DELETE_BANK_BRANCH_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/BankBranchConstant";


// create, update, delete and get all
export const getBankBranchesReducer = (state = { bankBranches: [] }, action) => {
  switch (action.type) {
    case BANK_BRANCH_REQUEST:
    case CREATE_BANK_BRANCH_REQUEST:
    case UPDATE_BANK_BRANCH_REQUEST:
    case DELETE_BANK_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: [...state.bankBranches, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: state.bankBranches.map((item) =>
          (item.bankBranchId === action.payload.bankBranchId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: action?.payload?.isDelete ?
          state.bankBranches.filter((item) =>
            item.bankBranchId !== action.payload.bankBranchId
          )
          :
          state.bankBranches.map((item) =>
            (item.bankBranchId === action.payload.bankBranchId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case BANK_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_BANK_BRANCH_FAIL:
    case UPDATE_BANK_BRANCH_FAIL:
    case DELETE_BANK_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};