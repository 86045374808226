import {
  CONFIG_OPTION_REQUEST,
  CONFIG_OPTION_SUCCESS,
  CONFIG_OPTION_FAIL,

  CREATE_CONFIG_OPTION_REQUEST,
  CREATE_CONFIG_OPTION_SUCCESS,
  CREATE_CONFIG_OPTION_FAIL,

  UPDATE_CONFIG_OPTION_REQUEST,
  UPDATE_CONFIG_OPTION_SUCCESS,
  UPDATE_CONFIG_OPTION_FAIL,

  DELETE_CONFIG_OPTION_REQUEST,
  DELETE_CONFIG_OPTION_SUCCESS,
  DELETE_CONFIG_OPTION_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/ConfigOptionConstant";


// create, update, delete and get all
export const getConfigOptionsReducer = (state = { configOptions: [] }, action) => {
  switch (action.type) {
    case CONFIG_OPTION_REQUEST:
    case CREATE_CONFIG_OPTION_REQUEST:
    case UPDATE_CONFIG_OPTION_REQUEST:
    case DELETE_CONFIG_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CONFIG_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        configOptions: action.payload,
        message: null,
        status: null,
      };

    case CREATE_CONFIG_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        configOptions: [...state.configOptions, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_CONFIG_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        configOptions: state.configOptions.map((item) =>
          (item.coaConfigurationId === action.payload.coaConfigurationId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_CONFIG_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        configOptions: action?.payload?.isDelete ?
          state.configOptions.filter((item) =>
            item.coaConfigurationId !== action.payload.coaConfigurationId
          )
          :
          state.configOptions.map((item) =>
            (item.coaConfigurationId === action.payload.coaConfigurationId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case CONFIG_OPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_CONFIG_OPTION_FAIL:
    case UPDATE_CONFIG_OPTION_FAIL:
    case DELETE_CONFIG_OPTION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};