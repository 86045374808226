import {
  COA_GROUP_REQUEST,
  COA_GROUP_SUCCESS,
  COA_GROUP_FAIL,

  CREATE_COA_GROUP_REQUEST,
  CREATE_COA_GROUP_SUCCESS,
  CREATE_COA_GROUP_FAIL,

  UPDATE_COA_GROUP_REQUEST,
  UPDATE_COA_GROUP_SUCCESS,
  UPDATE_COA_GROUP_FAIL,

  DELETE_COA_GROUP_REQUEST,
  DELETE_COA_GROUP_SUCCESS,
  DELETE_COA_GROUP_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/CoaGroupConstant";


// create, update, delete and get all
export const getCoaGroupsReducer = (state = { coaGroups: [] }, action) => {
  switch (action.type) {
    case COA_GROUP_REQUEST:
    case CREATE_COA_GROUP_REQUEST:
    case UPDATE_COA_GROUP_REQUEST:
    case DELETE_COA_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case COA_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COA_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: [...state.coaGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_COA_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: state.coaGroups.map((item) =>
          (item.coaGroupId === action.payload.coaGroupId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: action?.payload?.isDelete ?
          state.coaGroups.filter((item) =>
            item.coaGroupId !== action.payload.coaGroupId
          )
          :
          state.coaGroups.map((item) =>
            (item.coaGroupId === action.payload.coaGroupId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case COA_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_COA_GROUP_FAIL:
    case UPDATE_COA_GROUP_FAIL:
    case DELETE_COA_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};