// create/post
export const CREATE_CURRENCY_REQUEST = "CREATE_CURRENCY_REQUEST";
export const CREATE_CURRENCY_SUCCESS = "CREATE_CURRENCY_SUCCESS";
export const CREATE_CURRENCY_FAIL = "CREATE_CURRENCY_FAIL";

// get
export const CURRENCY_REQUEST = "CURRENCY_REQUEST";
export const CURRENCY_SUCCESS = "CURRENCY_SUCCESS";
export const CURRENCY_FAIL = "CURRENCY_FAIL";

// update/put
export const UPDATE_CURRENCY_REQUEST = "UPDATE_CURRENCY_REQUEST";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAIL = "UPDATE_CURRENCY_FAIL";

// delete/put
export const DELETE_CURRENCY_REQUEST = "DELETE_CURRENCY_REQUEST";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAIL = "DELETE_CURRENCY_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";