import {
  BANK_REQUEST,
  BANK_SUCCESS,
  BANK_FAIL,

  CREATE_BANK_REQUEST,
  CREATE_BANK_SUCCESS,
  CREATE_BANK_FAIL,

  UPDATE_BANK_REQUEST,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAIL,

  DELETE_BANK_REQUEST,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/BankConstant";


// create, update, delete and get all
export const getBanksReducer = (state = { banks: [] }, action) => {
  switch (action.type) {
    case BANK_REQUEST:
    case CREATE_BANK_REQUEST:
    case UPDATE_BANK_REQUEST:
    case DELETE_BANK_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: [...state.banks, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: state.banks.map((item) =>
          (item.bankId === action.payload.bankId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: action?.payload?.isDelete ?
          state.banks.filter((item) =>
            item.bankId !== action.payload.bankId
          )
          :
          state.banks.map((item) =>
            (item.bankId === action.payload.bankId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case BANK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_BANK_FAIL:
    case UPDATE_BANK_FAIL:
    case DELETE_BANK_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};