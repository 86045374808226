// create/post
export const CREATE_CONFIG_OPTION_REQUEST = "CREATE_CONFIG_OPTION_REQUEST";
export const CREATE_CONFIG_OPTION_SUCCESS = "CREATE_CONFIG_OPTION_SUCCESS";
export const CREATE_CONFIG_OPTION_FAIL = "CREATE_CONFIG_OPTION_FAIL";

// get
export const CONFIG_OPTION_REQUEST = "CONFIG_OPTION_REQUEST";
export const CONFIG_OPTION_SUCCESS = "CONFIG_OPTION_SUCCESS";
export const CONFIG_OPTION_FAIL = "CONFIG_OPTION_FAIL";

// update/put
export const UPDATE_CONFIG_OPTION_REQUEST = "UPDATE_CONFIG_OPTION_REQUEST";
export const UPDATE_CONFIG_OPTION_SUCCESS = "UPDATE_CONFIG_OPTION_SUCCESS";
export const UPDATE_CONFIG_OPTION_FAIL = "UPDATE_CONFIG_OPTION_FAIL";

// delete/put
export const DELETE_CONFIG_OPTION_REQUEST = "DELETE_CONFIG_OPTION_REQUEST";
export const DELETE_CONFIG_OPTION_SUCCESS = "DELETE_CONFIG_OPTION_SUCCESS";
export const DELETE_CONFIG_OPTION_FAIL = "DELETE_CONFIG_OPTION_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";