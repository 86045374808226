import {
  DUNGKHAG_REQUEST,
  DUNGKHAG_SUCCESS,
  DUNGKHAG_FAIL,

  CREATE_DUNGKHAG_REQUEST,
  CREATE_DUNGKHAG_SUCCESS,
  CREATE_DUNGKHAG_FAIL,

  UPDATE_DUNGKHAG_REQUEST,
  UPDATE_DUNGKHAG_SUCCESS,
  UPDATE_DUNGKHAG_FAIL,

  DELETE_DUNGKHAG_REQUEST,
  DELETE_DUNGKHAG_SUCCESS,
  DELETE_DUNGKHAG_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/LocationMasters/DungkhagConstant";


// create, update, delete and get all
export const getDungkhagsReducer = (state = { dungkhags: [] }, action) => {
  switch (action.type) {
    case DUNGKHAG_REQUEST:
    case CREATE_DUNGKHAG_REQUEST:
    case UPDATE_DUNGKHAG_REQUEST:
    case DELETE_DUNGKHAG_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: [...state.dungkhags, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: state.dungkhags.map((item) =>
          (item.dungkhagId === action.payload.dungkhagId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: action?.payload?.isDelete ?
          state.dungkhags.filter((item) =>
            item.dungkhagId !== action.payload.dungkhagId
          )
          :
          state.dungkhags.map((item) =>
            (item.dungkhagId === action.payload.dungkhagId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case DUNGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_DUNGKHAG_FAIL:
    case UPDATE_DUNGKHAG_FAIL:
    case DELETE_DUNGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};