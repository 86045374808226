// create/post
export const CREATE_DEPARTMENT_REQUEST = "CREATE_DEPARTMENT_REQUEST";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAIL = "CREATE_DEPARTMENT_FAIL";

// get
export const DEPARTMENT_REQUEST = "DEPARTMENT_REQUEST";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL";

// update/put
export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL";

// delete/put
export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";