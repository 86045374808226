import {
  TENANT_TYPE_REQUEST,
  TENANT_TYPE_SUCCESS,
  TENANT_TYPE_FAIL,

  CREATE_TENANT_TYPE_REQUEST,
  CREATE_TENANT_TYPE_SUCCESS,
  CREATE_TENANT_TYPE_FAIL,

  UPDATE_TENANT_TYPE_REQUEST,
  UPDATE_TENANT_TYPE_SUCCESS,
  UPDATE_TENANT_TYPE_FAIL,

  DELETE_TENANT_TYPE_REQUEST,
  DELETE_TENANT_TYPE_SUCCESS,
  DELETE_TENANT_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/TenantMasters/TenantTypeConstant";


// create, update, delete and get all
export const getTenantTypesReducer = (state = { tenantTypes: [] }, action) => {
  switch (action.type) {
    case TENANT_TYPE_REQUEST:
    case CREATE_TENANT_TYPE_REQUEST:
    case UPDATE_TENANT_TYPE_REQUEST:
    case DELETE_TENANT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case TENANT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TENANT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantTypes: [...state.tenantTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TENANT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantTypes: state.tenantTypes.map((item) =>
          (item.tenantTypeId === action.payload.tenantTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_TENANT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantTypes: action?.payload?.isDelete ?
          state.tenantTypes.filter((item) =>
            item.tenantTypeId !== action.payload.tenantTypeId
          )
          :
          state.tenantTypes.map((item) =>
            (item.tenantTypeId === action.payload.tenantTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case TENANT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_TENANT_TYPE_FAIL:
    case UPDATE_TENANT_TYPE_FAIL:
    case DELETE_TENANT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};