// create/post
export const CREATE_STATUS_MASTER_REQUEST = "CREATE_STATUS_MASTER_REQUEST";
export const CREATE_STATUS_MASTER_SUCCESS = "CREATE_STATUS_MASTER_SUCCESS";
export const CREATE_STATUS_MASTER_FAIL = "CREATE_STATUS_MASTER_FAIL";

// get
export const STATUS_MASTER_REQUEST = "STATUS_MASTER_REQUEST";
export const STATUS_MASTER_SUCCESS = "STATUS_MASTER_SUCCESS";
export const STATUS_MASTER_FAIL = "STATUS_MASTER_FAIL";

// update/put
export const UPDATE_STATUS_MASTER_REQUEST = "UPDATE_STATUS_MASTER_REQUEST";
export const UPDATE_STATUS_MASTER_SUCCESS = "UPDATE_STATUS_MASTER_SUCCESS";
export const UPDATE_STATUS_MASTER_FAIL = "UPDATE_STATUS_MASTER_FAIL";

// delete/put
export const DELETE_STATUS_MASTER_REQUEST = "DELETE_STATUS_MASTER_REQUEST";
export const DELETE_STATUS_MASTER_SUCCESS = "DELETE_STATUS_MASTER_SUCCESS";
export const DELETE_STATUS_MASTER_FAIL = "DELETE_STATUS_MASTER_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";