import {
  CREATE_REVERSAL_REQUEST,
  CREATE_REVERSAL_SUCCESS,
  CREATE_REVERSAL_FAIL,

  REVERSAL_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/ReversalConstant";

export const getReversalReducer = (state = { receiptReversal: {} }, action) => {
  switch (action.type) {
    case CREATE_REVERSAL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_REVERSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_REVERSAL_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case REVERSAL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};