import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,

  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAIL,

  FIND_CURRENT_USER_DATA_REQUEST,
  FIND_CURRENT_USER_DATA_SUCCESS,
  FIND_CURRENT_USER_DATA_FAIL,

  FIND_CURRENT_USER_TENANT_REQUEST,
  FIND_CURRENT_USER_TENANT_SUCCESS,
  FIND_CURRENT_USER_TENANT_FAIL,

  DELETE_USER_DATA_SUCCESS,

  LOCK_USER_DATA_REQUEST,
  LOCK_USER_DATA_SUCCESS,
  LOCK_USER_DATA_FAIL,

  CLEAR_ERROR,
} from "../../../constants/UserManagement/User/UserManagementConstant";


// create, update, delete and get all
export const getUsersDataReducer = (state = { usersData: [] }, action) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
    case UPDATE_USER_DATA_REQUEST:
    case LOCK_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        usersData: action.payload,
        message: null,
        status: null,
      };

    case UPDATE_USER_DATA_SUCCESS:
    case LOCK_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        usersData: state.usersData.map((item) =>
          (item.userId === action.payload.userId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        usersData: state.usersData.filter((item) =>
          item.userId !== action.payload.userId
        ),
        message: action.message,
        status: action.status,
      };

    case USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_USER_DATA_FAIL:
    case LOCK_USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

//Current user find
export const findCurrentUserDataReducer = (state = { currentUserData: {} }, action) => {
  switch (action.type) {
    case FIND_CURRENT_USER_DATA_REQUEST:
      return {
        currentUserData: {},
        loading: true,
      };

    case FIND_CURRENT_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUserData: action.payload,
      };

    case FIND_CURRENT_USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        currentUserData: {},
        error: action.payload
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// find one
export const findCurrentUserTenantReducer = (state = { currentUserTenant: {} }, action) => {
  switch (action.type) {
    case FIND_CURRENT_USER_TENANT_REQUEST:
      return {
        loading: true,
        currentUserTenant: {},
      };

    case FIND_CURRENT_USER_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUserTenant: action.payload,
      };

    case FIND_CURRENT_USER_TENANT_FAIL:
      return {
        ...state,
        loading: false,
        currentUserTenant: {},
        error: action.payload,
      };

    case "EMPTY_CURRENT_USER_TENANT":
      return {
        currentUserTenant: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};