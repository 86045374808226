import {
  SERVICE_REQUEST,
  SERVICE_SUCCESS,
  SERVICE_FAIL,

  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAIL,

  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,

  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/ServiceConstant";


// create, update, delete and get all
export const getServicesReducer = (state = { services: [] }, action) => {
  switch (action.type) {
    case SERVICE_REQUEST:
    case CREATE_SERVICE_REQUEST:
    case UPDATE_SERVICE_REQUEST:
    case DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
        message: null,
        status: null,
      };

    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [...state.services, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: state.services.map((item) =>
          (item.serviceId === action.payload.serviceId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action?.payload?.isDelete ?
          state.services.filter((item) =>
            item.serviceId !== action.payload.serviceId
          )
          :
          state.services.map((item) =>
            (item.serviceId === action.payload.serviceId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_SERVICE_FAIL:
    case UPDATE_SERVICE_FAIL:
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};