import {
  REVENUE_GROUP_REQUEST,
  REVENUE_GROUP_SUCCESS,
  REVENUE_GROUP_FAIL,

  CREATE_REVENUE_GROUP_REQUEST,
  CREATE_REVENUE_GROUP_SUCCESS,
  CREATE_REVENUE_GROUP_FAIL,

  UPDATE_REVENUE_GROUP_REQUEST,
  UPDATE_REVENUE_GROUP_SUCCESS,
  UPDATE_REVENUE_GROUP_FAIL,

  DELETE_REVENUE_GROUP_REQUEST,
  DELETE_REVENUE_GROUP_SUCCESS,
  DELETE_REVENUE_GROUP_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/CoaMasters/RevenueGroupConstant";


// create, update, delete and get all
export const getRevenueGroupsReducer = (state = { revenueGroups: [] }, action) => {
  switch (action.type) {
    case REVENUE_GROUP_REQUEST:
    case CREATE_REVENUE_GROUP_REQUEST:
    case UPDATE_REVENUE_GROUP_REQUEST:
    case DELETE_REVENUE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REVENUE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REVENUE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueGroups: [...state.revenueGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REVENUE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueGroups: state.revenueGroups.map((item) =>
          (item.revenueGroupId === action.payload.revenueGroupId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_REVENUE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueGroups: action?.payload?.isDelete ?
          state.revenueGroups.filter((item) =>
            item.revenueGroupId !== action.payload.revenueGroupId
          )
          :
          state.revenueGroups.map((item) =>
            (item.revenueGroupId === action.payload.revenueGroupId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case REVENUE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REVENUE_GROUP_FAIL:
    case UPDATE_REVENUE_GROUP_FAIL:
    case DELETE_REVENUE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};