import {
  DZONGKHAG_REQUEST,
  DZONGKHAG_SUCCESS,
  DZONGKHAG_FAIL,

  CREATE_DZONGKHAG_REQUEST,
  CREATE_DZONGKHAG_SUCCESS,
  CREATE_DZONGKHAG_FAIL,

  UPDATE_DZONGKHAG_REQUEST,
  UPDATE_DZONGKHAG_SUCCESS,
  UPDATE_DZONGKHAG_FAIL,

  DELETE_DZONGKHAG_REQUEST,
  DELETE_DZONGKHAG_SUCCESS,
  DELETE_DZONGKHAG_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/LocationMasters/DzongkhagConstant";


// create, update, delete and get all
export const getDzongkhagsReducer = (state = { dzongkhags: [] }, action) => {
  switch (action.type) {
    case DZONGKHAG_REQUEST:
    case CREATE_DZONGKHAG_REQUEST:
    case UPDATE_DZONGKHAG_REQUEST:
    case DELETE_DZONGKHAG_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhags: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhags: [...state.dzongkhags, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhags: state.dzongkhags.map((item) =>
          (item.dzongkhagId === action.payload.dzongkhagId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhags: action?.payload?.isDelete ?
          state.dzongkhags.filter((item) =>
            item.dzongkhagId !== action.payload.dzongkhagId
          )
          :
          state.dzongkhags.map((item) =>
            (item.dzongkhagId === action.payload.dzongkhagId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_DZONGKHAG_FAIL:
    case UPDATE_DZONGKHAG_FAIL:
    case DELETE_DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};