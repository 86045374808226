// create/post
export const CREATE_MODE_OF_PAYMENT_REQUEST = "CREATE_MODE_OF_PAYMENT_REQUEST";
export const CREATE_MODE_OF_PAYMENT_SUCCESS = "CREATE_MODE_OF_PAYMENT_SUCCESS";
export const CREATE_MODE_OF_PAYMENT_FAIL = "CREATE_MODE_OF_PAYMENT_FAIL";

// get
export const MODE_OF_PAYMENT_REQUEST = "MODE_OF_PAYMENT_REQUEST";
export const MODE_OF_PAYMENT_SUCCESS = "MODE_OF_PAYMENT_SUCCESS";
export const MODE_OF_PAYMENT_FAIL = "MODE_OF_PAYMENT_FAIL";

// update/put
export const UPDATE_MODE_OF_PAYMENT_REQUEST = "UPDATE_MODE_OF_PAYMENT_REQUEST";
export const UPDATE_MODE_OF_PAYMENT_SUCCESS = "UPDATE_MODE_OF_PAYMENT_SUCCESS";
export const UPDATE_MODE_OF_PAYMENT_FAIL = "UPDATE_MODE_OF_PAYMENT_FAIL";

// delete/put
export const DELETE_MODE_OF_PAYMENT_REQUEST = "DELETE_MODE_OF_PAYMENT_REQUEST";
export const DELETE_MODE_OF_PAYMENT_SUCCESS = "DELETE_MODE_OF_PAYMENT_SUCCESS";
export const DELETE_MODE_OF_PAYMENT_FAIL = "DELETE_MODE_OF_PAYMENT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";