// create/post
export const CREATE_ADVANCE_PAYMENT_TYPE_REQUEST = "CREATE_ADVANCE_PAYMENT_TYPE_REQUEST";
export const CREATE_ADVANCE_PAYMENT_TYPE_SUCCESS = "CREATE_ADVANCE_PAYMENT_TYPE_SUCCESS";
export const CREATE_ADVANCE_PAYMENT_TYPE_FAIL = "CREATE_ADVANCE_PAYMENT_TYPE_FAIL";

// get
export const ADVANCE_PAYMENT_TYPE_REQUEST = "ADVANCE_PAYMENT_TYPE_REQUEST";
export const ADVANCE_PAYMENT_TYPE_SUCCESS = "ADVANCE_PAYMENT_TYPE_SUCCESS";
export const ADVANCE_PAYMENT_TYPE_FAIL = "ADVANCE_PAYMENT_TYPE_FAIL";

// update/put
export const UPDATE_ADVANCE_PAYMENT_TYPE_REQUEST = "UPDATE_ADVANCE_PAYMENT_TYPE_REQUEST";
export const UPDATE_ADVANCE_PAYMENT_TYPE_SUCCESS = "UPDATE_ADVANCE_PAYMENT_TYPE_SUCCESS";
export const UPDATE_ADVANCE_PAYMENT_TYPE_FAIL = "UPDATE_ADVANCE_PAYMENT_TYPE_FAIL";

// delete/put
export const DELETE_ADVANCE_PAYMENT_TYPE_REQUEST = "DELETE_ADVANCE_PAYMENT_TYPE_REQUEST";
export const DELETE_ADVANCE_PAYMENT_TYPE_SUCCESS = "DELETE_ADVANCE_PAYMENT_TYPE_SUCCESS";
export const DELETE_ADVANCE_PAYMENT_TYPE_FAIL = "DELETE_ADVANCE_PAYMENT_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";