import {
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_FAIL,

  CREATE_CURRENCY_REQUEST,
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAIL,

  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAIL,

  DELETE_CURRENCY_REQUEST,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,

  CLEAR_ERROR,
} from "../../../constants/Master/OtherMasters/CurrencyConstant";


// create, update, delete and get all
export const getCurrenciesReducer = (state = { currencies: [] }, action) => {
  switch (action.type) {
    case CURRENCY_REQUEST:
    case CREATE_CURRENCY_REQUEST:
    case UPDATE_CURRENCY_REQUEST:
    case DELETE_CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
        message: null,
        status: null,
      };

    case CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: [...state.currencies, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: state.currencies.map((item) =>
          (item.currencyId === action.payload.currencyId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action?.payload?.isDelete ?
          state.currencies.filter((item) =>
            item.currencyId !== action.payload.currencyId
          )
          :
          state.currencies.map((item) =>
            (item.currencyId === action.payload.currencyId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_CURRENCY_FAIL:
    case UPDATE_CURRENCY_FAIL:
    case DELETE_CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};