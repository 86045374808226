import {
  COA_SERVICE_MAP_REQUEST,
  COA_SERVICE_MAP_SUCCESS,
  COA_SERVICE_MAP_FAIL,

  CREATE_COA_SERVICE_MAP_REQUEST,
  CREATE_COA_SERVICE_MAP_SUCCESS,
  CREATE_COA_SERVICE_MAP_FAIL,

  UPDATE_COA_SERVICE_MAP_REQUEST,
  UPDATE_COA_SERVICE_MAP_SUCCESS,
  UPDATE_COA_SERVICE_MAP_FAIL,

  DELETE_COA_SERVICE_MAP_REQUEST,
  DELETE_COA_SERVICE_MAP_SUCCESS,
  DELETE_COA_SERVICE_MAP_FAIL,

  CLEAR_ERROR,
} from "../../../constants/CoaManagement/CoaMapping/CoaServiceMapConstant";


// create, update, delete and get all
export const getCoaServiceMapsReducer = (state = { coaServiceMaps: [] }, action) => {
  switch (action.type) {
    case COA_SERVICE_MAP_REQUEST:
    case CREATE_COA_SERVICE_MAP_REQUEST:
    case UPDATE_COA_SERVICE_MAP_REQUEST:
    case DELETE_COA_SERVICE_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case COA_SERVICE_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaServiceMaps: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COA_SERVICE_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaServiceMaps: [...state.coaServiceMaps, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_COA_SERVICE_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaServiceMaps: state.coaServiceMaps.map((item) =>
          (item.coaServiceMappingId === action.payload.coaServiceMappingId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_SERVICE_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaServiceMaps: action?.payload?.isDelete ?
          state.coaServiceMaps.filter((item) =>
            item.coaServiceMappingId !== action.payload.coaServiceMappingId
          )
          :
          state.coaServiceMaps.map((item) =>
            (item.coaServiceMappingId === action.payload.coaServiceMappingId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case COA_SERVICE_MAP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_COA_SERVICE_MAP_FAIL:
    case UPDATE_COA_SERVICE_MAP_FAIL:
    case DELETE_COA_SERVICE_MAP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};