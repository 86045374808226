// create/post
export const CREATE_REVENUE_OFFICE_REQUEST = "CREATE_REVENUE_OFFICE_REQUEST";
export const CREATE_REVENUE_OFFICE_SUCCESS = "CREATE_REVENUE_OFFICE_SUCCESS";
export const CREATE_REVENUE_OFFICE_FAIL = "CREATE_REVENUE_OFFICE_FAIL";

// get
export const REVENUE_OFFICE_REQUEST = "REVENUE_OFFICE_REQUEST";
export const REVENUE_OFFICE_SUCCESS = "REVENUE_OFFICE_SUCCESS";
export const REVENUE_OFFICE_FAIL = "REVENUE_OFFICE_FAIL";

// update/put
export const UPDATE_REVENUE_OFFICE_REQUEST = "UPDATE_REVENUE_OFFICE_REQUEST";
export const UPDATE_REVENUE_OFFICE_SUCCESS = "UPDATE_REVENUE_OFFICE_SUCCESS";
export const UPDATE_REVENUE_OFFICE_FAIL = "UPDATE_REVENUE_OFFICE_FAIL";

// delete/put
export const DELETE_REVENUE_OFFICE_REQUEST = "DELETE_REVENUE_OFFICE_REQUEST";
export const DELETE_REVENUE_OFFICE_SUCCESS = "DELETE_REVENUE_OFFICE_SUCCESS";
export const DELETE_REVENUE_OFFICE_FAIL = "DELETE_REVENUE_OFFICE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";