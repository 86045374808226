import {
  TENANT_BANK_ACCOUNT_MAP_REQUEST,
  TENANT_BANK_ACCOUNT_MAP_SUCCESS,
  TENANT_BANK_ACCOUNT_MAP_FAIL,

  CREATE_TENANT_BANK_ACCOUNT_MAP_REQUEST,
  CREATE_TENANT_BANK_ACCOUNT_MAP_SUCCESS,
  CREATE_TENANT_BANK_ACCOUNT_MAP_FAIL,

  UPDATE_TENANT_BANK_ACCOUNT_MAP_REQUEST,
  UPDATE_TENANT_BANK_ACCOUNT_MAP_SUCCESS,
  UPDATE_TENANT_BANK_ACCOUNT_MAP_FAIL,

  DELETE_TENANT_BANK_ACCOUNT_MAP_REQUEST,
  DELETE_TENANT_BANK_ACCOUNT_MAP_SUCCESS,
  DELETE_TENANT_BANK_ACCOUNT_MAP_FAIL,

  CLEAR_ERROR,
} from "../../../constants/CoaManagement/CoaMapping/TenantBankAccountMapConstant";


// create, update, delete and get all
export const getTenantBankAccountMapsReducer = (state = { tenantBankAccountMaps: [] }, action) => {
  switch (action.type) {
    case TENANT_BANK_ACCOUNT_MAP_REQUEST:
    case CREATE_TENANT_BANK_ACCOUNT_MAP_REQUEST:
    case UPDATE_TENANT_BANK_ACCOUNT_MAP_REQUEST:
    case DELETE_TENANT_BANK_ACCOUNT_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case TENANT_BANK_ACCOUNT_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccountMaps: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TENANT_BANK_ACCOUNT_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccountMaps: [...state.tenantBankAccountMaps, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TENANT_BANK_ACCOUNT_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccountMaps: state.tenantBankAccountMaps.map((item) =>
          (item.coaServiceMappingId === action.payload.coaServiceMappingId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_TENANT_BANK_ACCOUNT_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantBankAccountMaps: action?.payload?.isDelete ?
          state.tenantBankAccountMaps.filter((item) =>
            item.coaServiceMappingId !== action.payload.coaServiceMappingId
          )
          :
          state.tenantBankAccountMaps.map((item) =>
            (item.coaServiceMappingId === action.payload.coaServiceMappingId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case TENANT_BANK_ACCOUNT_MAP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_TENANT_BANK_ACCOUNT_MAP_FAIL:
    case UPDATE_TENANT_BANK_ACCOUNT_MAP_FAIL:
    case DELETE_TENANT_BANK_ACCOUNT_MAP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};