// create/post
export const CREATE_DZONGKHAG_REQUEST = "CREATE_DZONGKHAG_REQUEST";
export const CREATE_DZONGKHAG_SUCCESS = "CREATE_DZONGKHAG_SUCCESS";
export const CREATE_DZONGKHAG_FAIL = "CREATE_DZONGKHAG_FAIL";

// get
export const DZONGKHAG_REQUEST = "DZONGKHAG_REQUEST";
export const DZONGKHAG_SUCCESS = "DZONGKHAG_SUCCESS";
export const DZONGKHAG_FAIL = "DZONGKHAG_FAIL";

// update/put
export const UPDATE_DZONGKHAG_REQUEST = "UPDATE_DZONGKHAG_REQUEST";
export const UPDATE_DZONGKHAG_SUCCESS = "UPDATE_DZONGKHAG_SUCCESS";
export const UPDATE_DZONGKHAG_FAIL = "UPDATE_DZONGKHAG_FAIL";

// delete/put
export const DELETE_DZONGKHAG_REQUEST = "DELETE_DZONGKHAG_REQUEST";
export const DELETE_DZONGKHAG_SUCCESS = "DELETE_DZONGKHAG_SUCCESS";
export const DELETE_DZONGKHAG_FAIL = "DELETE_DZONGKHAG_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";