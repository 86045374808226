// create/post
export const CREATE_PAYMENT_COLLECTION_METHOD_REQUEST = "CREATE_PAYMENT_COLLECTION_METHOD_REQUEST";
export const CREATE_PAYMENT_COLLECTION_METHOD_SUCCESS = "CREATE_PAYMENT_COLLECTION_METHOD_SUCCESS";
export const CREATE_PAYMENT_COLLECTION_METHOD_FAIL = "CREATE_PAYMENT_COLLECTION_METHOD_FAIL";

// get
export const PAYMENT_COLLECTION_METHOD_REQUEST = "PAYMENT_COLLECTION_METHOD_REQUEST";
export const PAYMENT_COLLECTION_METHOD_SUCCESS = "PAYMENT_COLLECTION_METHOD_SUCCESS";
export const PAYMENT_COLLECTION_METHOD_FAIL = "PAYMENT_COLLECTION_METHOD_FAIL";

// update/put
export const UPDATE_PAYMENT_COLLECTION_METHOD_REQUEST = "UPDATE_PAYMENT_COLLECTION_METHOD_REQUEST";
export const UPDATE_PAYMENT_COLLECTION_METHOD_SUCCESS = "UPDATE_PAYMENT_COLLECTION_METHOD_SUCCESS";
export const UPDATE_PAYMENT_COLLECTION_METHOD_FAIL = "UPDATE_PAYMENT_COLLECTION_METHOD_FAIL";

// delete/put
export const DELETE_PAYMENT_COLLECTION_METHOD_REQUEST = "DELETE_PAYMENT_COLLECTION_METHOD_REQUEST";
export const DELETE_PAYMENT_COLLECTION_METHOD_SUCCESS = "DELETE_PAYMENT_COLLECTION_METHOD_SUCCESS";
export const DELETE_PAYMENT_COLLECTION_METHOD_FAIL = "DELETE_PAYMENT_COLLECTION_METHOD_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";